import PropTypes from "prop-types";
import React from "react";

import { Button, Typography } from "@material-ui/core";
import YouTubeIcon from "@material-ui/icons/YouTube";

ButtonYouTube.propTypes = {
  label: PropTypes.node,
};

export default function ButtonYouTube({ label, ...props }) {
  return (
    <Button
      fullWidth
      size="large"
      sx={{
        textTransform: "none",
        textAlign: "left",
        padding: "8px 12px",
        color: "text.primary",
      }}
      variant="text"
      {...props}
    >
      <YouTubeIcon
        sx={{
          fontSize: "4rem",
          margin: "-12px 0 -12px 0",
        }}
      />
      <Typography sx={{ lineHeight: 1.3, ml: "12px" }} variant="body1">
        {label}
      </Typography>
    </Button>
  );
}

/**
 * Схема лизинговой сделки
 */
import PropTypes from "prop-types";
import React from "react";

import { Box, Typography } from "@material-ui/core";
import { SectionContainer, Step } from "@product-site-frontend/shared";
import parse from "html-react-parser";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination]);

Card.propTypes = {
  description: PropTypes.string,
  index: PropTypes.number,
  title: PropTypes.string,
};

function Card({ description, index, title }) {
  return (
    <Box sx={{ width: { lg: 0.25 } }}>
      <Box sx={{ display: "flex", mb: 3, alignItems: "center" }}>
        <Step>{index + 1}</Step>
        {index !== 3 && (
          <Box
            sx={{
              bgcolor: "primary.main",
              flex: 1,
              height: "3px",
              ml: { xs: 5, md: 8 },
              mr: { xs: 4, md: 8 },
            }}
          />
        )}
      </Box>
      <Box pr={{ xs: 3, lg: 6 }}>
        <Typography mb={3} mt={0} textAlign={{ lg: "left" }} variant="h3" component="div">
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>
    </Box>
  );
}

SectionLeasingScheme.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    caption: PropTypes.string,
    steps: PropTypes.array,
  }),
};

export default function SectionLeasingScheme({ content }) {
  return (
    <SectionContainer id={"sectionLeasingScheme"} title="Схема лизинговой сделки">
      <Box
        sx={{
          display: { lg: "none" },
        }}
      >
        <Swiper
          slidesPerView={1.5}
          spaceBetween={16}
          style={{ marginRight: -16, marginBottom: 32 }}
        >
          {content.steps.map((step, i) => (
            // eslint-disable-next-line react/prop-types
            <SwiperSlide key={step.title}>
              <Card index={i} {...step} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Box sx={{ alignItems: "flex-start", display: { xs: "none", lg: "flex" }, mb: 6 }}>
        {content.steps.map((step, i) => (
          <Card index={i} key={i} {...step} />
        ))}
      </Box>
      <Typography component="div" sx={{ textAlign: { lg: "center" } }} variant="h4">
        {parse(content.caption)}
      </Typography>
    </SectionContainer>
  );
}

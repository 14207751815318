export default function getAppTrackingParams(location = {}) {
  const { pathname, href } = location;

  if (pathname.includes('lizing-meditsinskogo-oborudovaniya')) {
    return {
      app_channel: 'thematic_landing',
      app_source: 'lizing-meditsinskogo-oborudovaniya',
      landing_url: href,
      channel: 'thematic_landing',
      landing_slug: 'medicine',
    };
  }

  if (pathname.includes('shiny-v-lizing')) {
    return {
      app_channel: 'thematic_landing',
      app_source: 'shiny-v-lizing',
      landing_url: href,
      channel: 'thematic_landing',
      landing_slug: 'shini',
    };
  }

  if (process.env.GATSBY_PARTNER_SLUG) {
    return {
      app_channel: 'partner_landing',
      app_source: process.env.GATSBY_PARTNER_SLUG,
      landing_url: href,
      channel: 'partner_landing',
      landing_slug: process.env.GATSBY_PARTNER_SLUG,
    };
  }

  if (process.env.REGION_SLUG) {
    return { channel: 'city_landing', landing_slug: process.env.REGION_SLUG };
  }

  return { app_channel: 'main_site', landing_url: href, channel: 'main_site' };
}

import PropTypes from "prop-types";
import React, { useState, useRef } from "react";

import {
  IconButton,
  Link,
  List,
  ListItem,
  Popover,
  Typography,
  Button,
  Box,
  MenuList,
  MenuItem,
  useMediaQuery,
  useTheme,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
} from "@material-ui/core";
import LoginIcon from "@material-ui/icons/Login";
import {
  ElevationAppBar,
  LogoArenzaDot,
  LogoArenzaMed,
} from "@product-site-frontend/shared";
import { useLocation } from "@reach/router";
import { Link as GatsbyLink } from "gatsby";

import { NAV_LINKS } from "../constants/nav-links";
import IconBurger from "./IconBurger";

AppBar.propTypes = {
  drawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
};

export default function AppBar({ drawerOpen, toggleDrawer }) {
  const theme = useTheme();
  const { pathname } = useLocation();

  const matchesMd = useMediaQuery(theme.breakpoints.only("md"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    if (matchesMd) {
      setAnchorEl(event.currentTarget);
    } else {
      toggleDrawer();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "nav-popover" : undefined;

  const isMedicine = pathname.includes("lizing-meditsinskogo-oborudovaniya");

  let hideLogo = isMedicine;







  const [openAuth, setOpenAuth] = useState(false);
  const anchorAuthRef = useRef(null);

  const handleToggleAuth = () => {
    setOpenAuth((prevAuthOpen) => !prevAuthOpen);
  };

  const handleCloseAuth = (event) => {
    if (anchorAuthRef.current && anchorAuthRef.current.contains(event.target)) {
      return;
    }

    setOpenAuth(false);
  };

  function handleListAuthKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenAuth(false);
    } else if (event.key === "Escape") {
      setOpenAuth(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevAuthOpen = useRef(openAuth);
  React.useEffect(() => {
    if (prevAuthOpen.current === true && openAuth === false) {
      anchorAuthRef.current.focus();
    }

    prevAuthOpen.current = openAuth;
  }, [openAuth]);


  return (
    <ElevationAppBar hideLogo={hideLogo}>
      <Box sx={{ display: { xs: "flex", lg: "none" } }}>
        <IconButton edge="end" onClick={handleClick}>
          <IconBurger open={drawerOpen || open} />
        </IconButton>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 48,
            horizontal: 54,
          }}
          id={id}
          onClose={handleClose}
          open={open}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              backgroundImage: theme =>
                `linear-gradient(332deg, ${theme.palette.primary.light} 10%, ${theme.palette.primary.main} 60%)`,
              color: "white",
              py: 3,
              width: 600,
            }}
          >
            <List>
              {NAV_LINKS.map(({ link, text }) => (
                <ListItem button component="a" href={link} key={link}>
                  <Typography
                    sx={{ fontSize: 20, fontWeight: 700, px: 3, py: 2 }}
                    variant="subtitle2"
                  >
                    {text}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Popover>
        <Box sx={{ marginRight: '16px' }} />
      </Box>

      {isMedicine && (
        <Box display="flex" flexShrink={0} height={{ xs: 24, lg: 34 }}>
          <Box component={GatsbyLink} height="inherit" to="/">
            <LogoArenzaDot />
          </Box>
          <Box component={GatsbyLink} height="inherit" to="/lizing-meditsinskogo-oborudovaniya">
            <LogoArenzaMed />
          </Box>
        </Box>
      )}

      <Box ml="auto" />
      <Box component="nav" sx={{ display: { xs: "none", lg: "flex" } }}>
        {NAV_LINKS.map(({ link, text }) => (
          <Link
            color="#6E7884"
            href={link}
            key={link}
            sx={{ mr: 3, fontWeight: 500 }}
            underline="none"
            variant="body1"
          >
            {text}
          </Link>
        ))}
      </Box>

      <Box>
        <Button
          aria-controls={openAuth ? "composition-menu-auth" : undefined}
          aria-expanded={openAuth ? "true" : undefined}
          aria-haspopup="true"
          id="composition-button-auth"
          onClick={handleToggleAuth}
          ref={anchorAuthRef}
          sx={{
            color: '#6E7884',
            textTransform: 'none',
            fontWeight: '500',
            padding: { xs: "3px 9px", lg: "5px 15px" },
            fontSize: { xs: "0.8125rem", lg: "16px" },
            border: {
              xs: "none", lg: '1px solid #E7E8EA'
            },
            borderRadius: '8px',

            '&:hover':{
              color: '#FF780F',

              '& svg path': {
                fill: '#FF780F',
              },
            },
          }}
        >
          <Typography sx={{display: { xs: "none", lg: "flex" }}} variant="inherit">Войти</Typography>
          <LoginIcon sx={{ marginLeft: '10px', color: '#B6BBC2' }} />
        </Button>

        <Popper
          anchorEl={anchorAuthRef.current}
          disablePortal
          open={openAuth}
          placement="bottom-start"
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "right top" : "right bottom",
                boxShadow: 'rgba(170, 170, 170, 0.2) 0px 5px 5px -3px, rgba(170, 170, 170, 0.14) 0px 8px 10px 1px, rgba(170, 170, 170, 0.12) 0px 3px 14px 2px',
                marginTop: '6px',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseAuth}>
                  <MenuList
                    aria-labelledby="composition-button-auth"
                    autoFocusItem={openAuth}
                    id="composition-menu-auth"
                    onKeyDown={handleListAuthKeyDown}
                  >
                    <MenuItem onClick={handleCloseAuth}>
                      <Box component={GatsbyLink} height="inherit" to={`${process.env.GATSBY_ACCOUNT_URL}/login`}>
                        <Typography sx={{display: "flex"}} variant="inherit">Для клиентов</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem onClick={handleCloseAuth}>
                      <Box component={GatsbyLink} height="inherit" to={`${process.env.GATSBY_ACCOUNT_URL}/partnership/login`}>
                        <Typography sx={{display: "flex"}} variant="inherit">Для партнеров</Typography>
                      </Box>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>

    </ElevationAppBar>
  );
}
